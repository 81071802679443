<template>
  <div class="container ml-khoa-hoc-playlist min-vh-100 mb-5" v-if="detail">
    <div class="breadcrumd-custom"><nuxt-link to="/thu-vien-cua-toi?tab=1">Thư viện của tôi </nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">{{detail.name}}</span></div>
    <ViewMode :title="detail.name" @mode="changeMode" />
    <div class="line"></div>
    <div :class="{'bg-white px-3': !viewList}">
      <div class="row list-follow">
        <VideoItem v-for="item in listData" :key="item.id" :class="{'topic-item-list col-6 col-md-4 col-xl-3 pt-2': viewList, 'topic-item-detail col-sm-12 py-2': !viewList}" :view-list="viewList" :item="item" @showNotify="showNotify"/>
      </div>
      <NoData v-if="!listData.length && !loading" />
    </div>
    <Pagination class="mt-3" :total="query.total" :value="query.page" @change="loadData" :limit="query.limit" />
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
  </div>
</template>

<script>
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import VideoItem from '~/components/mlearn/video/item.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Pagination from '~/components/mlearn/pagination/index'
import Resource from '~/common/api/resource'
const playlistDetailResource = new Resource('library/playlist/getBySlug')
export default {
  components: {
    VideoItem,
    PlaylistNotify,
    Pagination,
    ViewMode
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const viewList = true
    return {
      viewList,
      detail: null,
      query: {
        limit: 12,
        page: 1,
        total: 0,
        slug: params.slug
      },
      listData: [],
      loading: false,
      flagNotify: false,
      textNotify: ''
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    redirectLink (link) {
      this.$router.push('/thu-vien-cua-toi/' + link)
    },
    changeMode (mode) {
      this.viewList = mode
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
      if (this.listData.length === 1) {
        return this.$router.push('/thu-vien-cua-toi?tab=1')
      }
      this.loadFollow()
    },
    async loadData (page = 1) {
      this.page = page
      if (this.loading) { return }
      this.loading = true
      const data = await playlistDetailResource.list(this.query)
      this.listData = data.data.items
      this.detail = data.data.info
      this.query.total = data.total
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-khoa-hoc-playlist{
    .line{
      border-bottom: 1px solid #EBEBEB;
      margin:10px 0;
    }
    .list-follow{
      .topic-item-detail{
        border-bottom: 1px solid #EBEBEB;
        &:first-child{
          padding-top: 24px !important;
        }
        &:last-child{
          padding-bottom: 24px !important;
          border-bottom: 0;
        }
      }
    }
  }
</style>
