<template>
  <div class="container ml-khoa-hoc-theo-doi min-vh-100 mb-5">
    <div class="breadcrumd-custom"><nuxt-link to="/thu-vien-cua-toi?tab=1">Thư viện của tôi </nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Theo dõi</span></div>
    <ViewMode title="Theo dõi" @mode="changeMode" />
    <div class="line"></div>
    <div :class="{'bg-white px-3': !viewList}">
      <div class="row list-follow">
        <VideoTopicItem v-for="item in listData" :key="item.id" :class="{'topic-item-list col-6 col-md-4 col-xl-3 pt-2': viewList, 'topic-item-detail col-sm-12 py-2': !viewList}" :view-list="viewList" :item="item" @showNotify="showNotify"/>
      </div>
      <NoData v-if="!listData.length && !loading" />
    </div>
    <Pagination class="mt-3" :total="query.total" :value="query.page" @change="loadFollow" :limit="query.limit" />
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
  </div>
</template>

<script>
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import VideoTopicItem from '~/components/mlearn/video/topic.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Pagination from '~/components/mlearn/pagination/index'
import Resource from '~/common/api/resource'
const followResource = new Resource('library/topic/listFollow')
export default {
  components: {
    VideoTopicItem,
    PlaylistNotify,
    Pagination,
    ViewMode
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  data () {
    const viewList = true
    return {
      viewList,
      listData: [],
      query: {
        limit: 12,
        page: 1,
        total: 0
      },
      loading: false,
      flagNotify: false,
      textNotify: ''
    }
  },
  created () {
    this.loadFollow()
  },
  methods: {
    async loadFollow (page = 1) {
      this.query.page = page
      if (this.loading) { return }
      this.loading = true
      const data = await followResource.list(this.query)
      this.listData = data.data
      this.query.total = data.total
      this.loading = false
    },
    redirectLink (link) {
      this.$router.push('/thu-vien-cua-toi/' + link)
    },
    changeMode (mode) {
      this.viewList = mode
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
      this.loadFollow()
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-khoa-hoc-theo-doi{
    .line{
      border-bottom: 1px solid #EBEBEB;
      margin:10px 0;
    }
    .list-follow{
      .topic-item-detail{
        border-bottom: 1px solid #EBEBEB;
        &:first-child{
          padding-top: 24px !important;
        }
        &:last-child{
          padding-bottom: 24px !important;
          border-bottom: 0;
        }
      }
    }
  }
</style>
