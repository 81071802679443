import { render, staticRenderFns } from "./da-xem.vue?vue&type=template&id=39e80134&scoped=true&"
import script from "./da-xem.vue?vue&type=script&lang=js&"
export * from "./da-xem.vue?vue&type=script&lang=js&"
import style0 from "./da-xem.vue?vue&type=style&index=0&id=39e80134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e80134",
  null
  
)

export default component.exports